<template>
  <div class="app">
    <div class="app-content">
      <div class="head_item">
        <span class="turnBackClass" @click="turnBack">
          <img src="../../../../assets/allImage/reback.png" alt="" />
          <span class="backFont">返回</span>
        </span>

        {{ GuideDetails[0].name }}
      </div>
      <div class="materials">
        <div class="handlingMaterials">
          <div class="cl_title">办理材料</div>
          <div class="flow">
            <div
              class="cont"
              v-html="
                GuideDetails[0].guide ? GuideDetails[0].guide : '暂无内容'
              "
            >
              <!-- {{  }} -->
            </div>
          </div>
        </div>
        <div class="handlingMaterials" style="margin-top: 0.625rem">
          <div class="cl_title">办理流程</div>
          <div class="btn">
            <div
              v-for="(ls, index) in btnList"
              :key="ls.id"
              class="cumms"
              :class="{ sonmms: countent == index }"
              @click="offlineFn(index)"
            >
              {{ ls.title }}
            </div>
          </div>
          <div class="flow_two">
            <div
              class="cont"
              v-if="countent == 0"
              v-html="
                GuideDetails[0].offline ? GuideDetails[0].offline : '暂无内容'
              "
            ></div>
            <div
              class="cont"
              v-else
              v-html="
                GuideDetails[0].online ? GuideDetails[0].online : '暂无内容'
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { headGuideDeatils } from '@/api/HomePage/guide.js';
import { mapState } from 'vuex';

export default {
  props: {},
  data() {
    return {
      countent: 0,
      btnList: [
        {
          id: 0,
          title: '线下流程',
        },
        {
          id: 1,
          title: '线上流程',
        },
      ],
      argument: {
        id: '',
      },
    };
  },
  computed: {
    ...mapState(['GuideDetails']),
  },
  methods: {
    offlineFn(index) {
      this.countent = index;
    },
    turnBack() {
      this.$router.push('/layout/etcguide');
    },
    getDeatils() {
      // headGuideDeatils(this.$router.query.id).then((request)=>{
      //   console.log('注销',request);
      // })
    },
  },
  beforeRouteUpdate() {
    this.getDeatils();
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  background-color: #f5f5f5;
  padding: 0.9375rem;

  .app-content {
    width: 80%;
    margin: 0.9375rem auto;
    .head_item {
      width: 100%;
      height: 2.8125rem;
      line-height: 2.8125rem;
      font-size: 1.125rem;
      font-weight: 600;
      color: #0aa4ee;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      .turnBackClass {
        align-items: center;
        img {
          width: 1.25rem;
          height: 20px;
        }
        .backFont {
          font-size: 14px;
        }
        cursor: pointer;
        position: absolute;
        left: 5rem;
        //top: 0.8125rem;
      }
    }
    .materials {
      width: 100%;
      font-size: 0.75rem;
      padding: 0 5rem;
      .handlingMaterials {
        width: 100%;
        padding: 0.9375rem;
        background-color: #fff;
        .cl_title {
          width: 100%;
          height: 2.1875rem;
          font-weight: 600;
          border-bottom: 0.0625rem dashed black;
          text-align: left;
        }
        .btn {
          width: 100%;
          height: 2.1875rem;
          text-align: left;
          line-height: 2.1875rem;
          display: flex;
          margin: 0.9375rem 0;
          .cumms {
            padding: 0 0.625rem;
            border-radius: 0.25rem;
            border: 0.0625rem #0aa4ee solid;
            margin-right: 0.9375rem;
            color: #0aa4ee;
            cursor: pointer;
          }
          .sonmms {
            padding: 0 0.625rem;
            border-radius: 0.25rem;
            background-color: #0aa4ee;
            margin-right: 0.9375rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .flow {
          width: 100%;
          line-height: 2.1875rem;
          .cont {
            width: 100%;
            // height: 2.1875rem;
            // background-color: yellow;
            text-align: left;
            line-height: 2.1875rem;
            ::v-deep img {
              max-width: 100% !important;
            }
          }
        }
        .flow_two {
          text-align: left;
          line-height: 2.1875rem;
        }
      }
    }
  }
}
</style>
