<template>
  <div class="app">
    <el-carousel
      v-if="BannerList.length > 0"
      arrow="always"
      ref="carousel"
      trigger="click"
      height="37.5rem"
      :interval="4000"
    >
      <el-carousel-item v-for="(item, index) in BannerList" :key="index">
        <img width="100%" height="100%" :src="imgUrl + item.fileUri" />
      </el-carousel-item>
    </el-carousel>

    <div v-else style="height: 38.125rem; width: 100%; overflow: hidden">
      <img
        width="100%"
        src="../../../../assets/allImage/etcguide/toutu_ETCgognenngshiyongzhinan@1x.png"
        alt=""
      />
    </div>
    <!-- <img
      v-else
      width="100%"
      src="../../../../assets/allImage/etcguide/toutu_ETCgognenngshiyongzhinan@1x.png"
      alt=""
    /> -->

    <commonNavigation style="width: 100%; margin-top: 0px"></commonNavigation>
    <guideDeatils></guideDeatils>
    <!-- 底部 -->
    <!-- <BottomField></BottomField> -->
    <router-view />
  </div>
</template>

<script>
import { slideshowList } from '@/api/index';
import commonNavigation from '@/layout/components/commonNavigation/index';
import guideDeatils from '@/layout/components/HomePage/guideDeatils/index';
import BottomField from '@/layout/components/HomePage/BottomField/index.vue';
// import {headGuideList} from '@/api/HomePage/guide.js'

export default {
  components: {
    BottomField,
    commonNavigation,
    guideDeatils,
  },
  data() {
    return {
      BannerList: [], //轮播图列表
      countent: 0,
      btnList: [
        {
          id: 0,
          title: '线下流程',
        },
        {
          id: 1,
          title: '线上流程',
        },
      ],
      list: [
        {
          id: 0,
          title:
            ' （1）个人客户：个人有效证件（车主本人亲自到捷通自营网点办理）。',
        },
        {
          id: 0,
          title:
            '（2）单位客户：单位有效证件、单位授权书、经办人有效身份证件。',
        },
        {
          id: 0,
          title: '（3）携带八桂行卡（如有）。',
        },
        {
          id: 0,
          title: '（4）如果是记账卡用户，需提供银行发起的注销凭证回执。',
        },
      ],

      sonList: [
        {
          id: 0,
          title:
            ' 客服营业厅受理注销业务31日后，通行费将退至用户指定的银行账户。所退的通行费余额以卡片余额及31日内产生的账户回补、补录交易之和为准，如不能读取卡片信息，则以捷通公司系统记录数据为准。',
        },
        {
          id: 1,
          title: '退费以银行转账形式办理，退费日期以银行账户到账日为准。 ',
        },
      ],
      argument: {
        type: '',
      },
    };
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newId, oldId) {
        this.rotationChartList(newId);
      },
    },
  },
  methods: {
    // 轮播图
    async rotationChartList(id) {
      const res = await slideshowList(id);
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          this.BannerList = res.data.data;
        }
      }
    },
    offlineFn(index) {
      this.countent = index;
    },
  },
  computed: {},
  created() {
    this.$store.state.commonNavigationList = [];
    // this.argument={
    //   type:this.$route.query.type
    // }
    // headGuideList(this.argument).then((request)=>{
    //   console.log('注销',this.zhuxiao);
    //   this.parentMessage = request.data.data[0]
    // })
  },
  mounted() {
    this.imgUrl = process.env.VUE_APP_BASE_URL;
    // this.getList()
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  background-color: #f5f5f5;
  // padding: 0.9375rem;
  .app-content {
    width: 80%;
    margin: 0.9375rem auto;
    .head_item {
      width: 100%;
      height: 2.8125rem;
      line-height: 2.8125rem;
      font-size: 1.125rem;
      font-weight: 600;
      color: #0aa4ee;
    }
    .materials {
      width: 100%;
      font-size: 0.75rem;
      .handlingMaterials {
        width: 100%;
        padding: 0.9375rem;
        background-color: #fff;
        .cl_title {
          width: 100%;
          height: 2.1875rem;
          font-weight: 600;
          border-bottom: 0.0625rem dashed black;
          text-align: left;
        }
        .btn {
          width: 100%;
          height: 2.1875rem;
          text-align: left;
          line-height: 2.1875rem;
          display: flex;
          margin: 0.9375rem;
          .cumms {
            padding: 0 0.625rem;
            border-radius: 0.25rem;
            border: 0.0625rem #0aa4ee solid;
            margin-right: 0.9375rem;
            color: #0aa4ee;
            cursor: pointer;
          }
          .sonmms {
            padding: 0 0.625rem;
            border-radius: 0.25rem;
            background-color: #0aa4ee;
            margin-right: 0.9375rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .flow {
          width: 100%;
          line-height: 2.1875rem;
          .cont {
            width: 100%;
            height: 2.1875rem;
            // background-color: yellow;
            text-align: left;
            line-height: 2.1875rem;
          }
        }
        .flow_two {
          text-align: left;
          line-height: 2.1875rem;
        }
      }
    }
  }
}
</style>
