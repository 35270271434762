var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"app-content"},[_c('div',{staticClass:"head_item"},[_c('span',{staticClass:"turnBackClass",on:{"click":_vm.turnBack}},[_c('img',{attrs:{"src":require("../../../../assets/allImage/reback.png"),"alt":""}}),_c('span',{staticClass:"backFont"},[_vm._v("返回")])]),_vm._v(" "+_vm._s(_vm.GuideDetails[0].name)+" ")]),_c('div',{staticClass:"materials"},[_c('div',{staticClass:"handlingMaterials"},[_c('div',{staticClass:"cl_title"},[_vm._v("办理材料")]),_c('div',{staticClass:"flow"},[_c('div',{staticClass:"cont",domProps:{"innerHTML":_vm._s(
              _vm.GuideDetails[0].guide ? _vm.GuideDetails[0].guide : '暂无内容'
            )}})])]),_c('div',{staticClass:"handlingMaterials",staticStyle:{"margin-top":"0.625rem"}},[_c('div',{staticClass:"cl_title"},[_vm._v("办理流程")]),_c('div',{staticClass:"btn"},_vm._l((_vm.btnList),function(ls,index){return _c('div',{key:ls.id,staticClass:"cumms",class:{ sonmms: _vm.countent == index },on:{"click":function($event){return _vm.offlineFn(index)}}},[_vm._v(" "+_vm._s(ls.title)+" ")])}),0),_c('div',{staticClass:"flow_two"},[(_vm.countent == 0)?_c('div',{staticClass:"cont",domProps:{"innerHTML":_vm._s(
              _vm.GuideDetails[0].offline ? _vm.GuideDetails[0].offline : '暂无内容'
            )}}):_c('div',{staticClass:"cont",domProps:{"innerHTML":_vm._s(
              _vm.GuideDetails[0].online ? _vm.GuideDetails[0].online : '暂无内容'
            )}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }